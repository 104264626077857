import React, { useState, useEffect } from 'react';
import Filter from './filter';

const ProgressBar = (props) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const updateProgress = () => setProgress(progress + 2);
    if (progress < 100) {
      setTimeout(updateProgress, 50);
    } else {
      props.onComplete();
    }
  }, [progress]);

  return (
    <div className="custom-progress-bar">
      <Filter percentage={progress} />
    </div>
  );
};

export default ProgressBar;