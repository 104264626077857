import React, { useState } from 'react';
import Home from './home';
import Login from './login';

import './assets/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  const [logged, setLogged] = useState(localStorage.getItem('token') ? true : false);
  return (
    <>
      { logged === true
        ? <Home handleLogout={() => setLogged(false)} />
        : <Login handleLogin={() => setLogged(true)} />
      }
    </>
  );
};

export default App;
