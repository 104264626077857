import React, { useState } from 'react';
import axios from 'axios';
import ErrorMessage from './errormsg';
import { backendUrl } from './backendUrl';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin(e);
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    axios
      .post(`${backendUrl}/auth/login/`, {
        user: {
          username: username,
          password: password
        }
      })
      .then(res => {
        const token = res.data.token;
        localStorage.setItem("token", token);
        props.handleLogin();
      })
      .catch(err => {
        setError(true);
      });
  };

  return (
    <div className='form-page__wrapper'>
      <div className='form-page__form-wrapper'>
        <div className='form-page__form-header'>
          <h2 className='form-page__form-heading'>Login</h2>
        </div>
        <form className='form' onSubmit={(e) => handleLogin(e)}>
          {error ? <ErrorMessage /> : null}
          <div className='form__field-wrapper'>
            <input
              className='form__field-input'
              type='text'
              name='username'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e)}
              placeholder='johnsmith'
            />
            <label className='form__field-label' htmlFor='username'>
              Username
          </label>
          </div>
          <div className='form__field-wrapper'>
            <input
              className='form__field-input'
              type='password'
              name='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => handleKeyPress(e)}
              placeholder='*****'
            />
            <label className='form__field-label' htmlFor='password'>
              Password
          </label>
          </div>
          <div className='form__submit-btn-wrapper'>
            <button className='form__submit-btn' type='submit'>
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;