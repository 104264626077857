import React from 'react'

const ErrorMessage = () => {
  return (
    <div className='form__error-wrapper js-form__err-animation'>
      <p className='form__error'>
        Invalid username or password
      </p>
    </div>
  );
};

export default ErrorMessage;