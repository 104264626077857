import React from 'react';
import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet
} from '@react-pdf/renderer';
import { useBarcode } from 'react-barcodes';

export const Barcode = ({ value, index }) => {
  const { inputRef } = useBarcode({
    value: value,
    options: {
      fontSize: 20,
      displayValue: false,
      width: 5,
      height: 250
    }
  });

  return (
    <div>
      <img id={index + "-" + value} ref={inputRef} />
    </div>
  );
};

const styles = StyleSheet.create({
  company_name: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 9,
  },
  description: {
    fontSize: 9,
  },
  sku_number: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 10,
  },
  country_name: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 11,
    marginTop: "2px",
  },
  mu_name: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 16,
    marginTop: "4px",
  }
});

const PdfDocument = ({ header, products, mode, pheight, pwidth }) => {
  return (
    <Document>
      <Page size={[pheight, pwidth]} orientation="landscape">
        {products.map((product, index) => {
          if (mode === "mu" || mode === "auto") {
            const imgUrl = document.getElementById(index + "-" + product[0]).src;
            return (
              <View
                wrap={false}
                style={{
                  width: 240,
                  height: 80,
                  fontSize: 14,
                  margin: "20px auto",
                  display: "flex",
                  textAlign: "center",
                }}
                key={index}
              >
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <Image src={imgUrl} />
                </View>
                <Text style={styles.mu_name}>{product[0]}</Text>
              </View>
            );
          } else if (mode === "standard" || mode === "coo") {
            const imgUrl = document.getElementById(index + "-" + product[1]).src;
            return (
              <View
                wrap={false}
                style={{
                  width: 240,
                  height: 80,
                  fontSize: 14,
                  margin: "auto",
                  marginTop: "10px",
                  display: "flex",
                  textAlign: "center",
                }}
                key={index}
              >
                <Text style={styles.company_name}>{product[0]}</Text>
                <Text style={styles.description}>{product[2]}</Text>
                <Text style={styles.description}>{header[3]}: {product[3]}</Text>
                <View
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: mode === "coo" ? 70 : 80
                  }}
                >
                  <Image src={imgUrl} />
                </View>
                <Text style={styles.sku_number}>{product[1]}</Text>
                {mode === "coo" && <Text style={styles.country_name}>{header[4]}: {product[4]}</Text>}
              </View>
            );
          } else if (mode === "asin") {
            const imgUrl1 = document.getElementById(index + "-" + product[1]).src;
            const imgUrl2 = document.getElementById(index + "-" + product[4]).src;
            return (
              <View
                wrap={false}
                style={{
                  width: 240,
                  height: 80,
                  fontSize: 14,
                  margin: "auto",
                  marginTop: "10px",
                  display: "flex",
                  textAlign: "center",
                }}
                key={index}
              >
                <Text style={styles.company_name}>{product[0]}</Text>
                <Text style={styles.description}>{product[2]}</Text>
                <Text style={styles.description}>{header[3]}: {product[3]}</Text>
                <View
                  style={{
                    height: 35,
                    display: "flex",
                    marginTop: "3px",
                    justifyContent: "center"
                  }}
                >
                  <Image src={imgUrl1} />
                </View>
                <Text style={styles.sku_number}>{header[1]}: {product[1]}</Text>
                <View
                  style={{
                    height: 35,
                    display: "flex",
                    marginTop: "3px",
                    justifyContent: "center"
                  }}
                >
                  <Image src={imgUrl2} />
                </View>
                <Text style={styles.sku_number}>{header[4]}: {product[4]}</Text>
              </View>
            );
          }
        })}
      </Page>
    </Document>
  );
};

export default PdfDocument;